import cx from 'classnames'
import { array } from 'helpers'
import dayjs, { Dayjs } from 'date'
import { Field, useField, useFieldState } from 'formular'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Href } from 'components/navigation'
import { Icon, Text } from 'components/dataDisplay'

import s from './DatePicker.scss'


export type DatePickerProps = {
  className?: string
  field: Field<string>
  format?: 'DD.MM.YYYY' | 'DD.MM' | 'YYYY.MM.DD' | 'YYYY-MM-DD'
  pickPeriod?: boolean
  pickedDates?: Edu.Date[]
  calendarsCount?: number
  withShadow?: boolean
  isPickOnlyBeforeToday?: boolean
  onChange?: (value: any) => void
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void
  onPicked?: (value: string) => void
}

const weekDays = [ 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс' ]
const daysInMonthView = 6 * 7

const DatePicker: React.FC<DatePickerProps> = (props) => {
  const {
    className,
    field,
    format = 'DD.MM.YYYY',
    pickPeriod,
    pickedDates,
    calendarsCount = 1,
    withShadow,
    isPickOnlyBeforeToday,
    onChange,
    onBlur,
    onPicked,
  } = props

  const firstDateOfCentralCalendarField = useField({
    value: field.state.value ? dayjs(field.state.value, format).startOf('month') : dayjs().startOf('month'),
  })
  const { value: stringFieldValue } = useFieldState(field)
  const { value: firstDateOfCentralCalendar, error } = useFieldState<Dayjs>(firstDateOfCentralCalendarField)

  const fromField = useField<Dayjs>({ value: field.state.value &&
    dayjs(field.state.value.split(' - ')[0], format) })

  const toField = useField<Dayjs>({ value: field.state.value &&
    dayjs(field.state.value.split(' - ')[1], format) })

  const { value: from, isChanged: isFromChanged } = useFieldState(fromField)
  const { value: to, isChanged: isToChanged } = useFieldState(toField)

  useEffect(() => {
    if (pickPeriod && from && to) {
      // TODO rework this, return { from, to }, remove onChange
      field.set(`${from.format(format)} - ${to.format(format)}`)

      if (typeof onChange === 'function') {
        onChange({ from, to })
      }
    }
  }, [ from, to, format, field, onChange, pickPeriod ])

  useEffect(() => {
    if (isFromChanged && isToChanged && typeof onPicked === 'function') {
      onPicked(field.state.value)
    }
  }, [ field.state.value, isFromChanged, isToChanged, onPicked ])

  const handleDayClick = useCallback((value) => {
    if (pickPeriod) {
      if (!from) {
        fromField.set(value)
      } else if (!to) {
        if (from.isAfter(value)) {
          return
        }
        toField.set(value)
      } else {
        fromField.set(value)
        toField.set(null)
        toField.state.isChanged = false
      }
    } else {
      const fieldValue = value.format(format)

      field.set(fieldValue)
      firstDateOfCentralCalendarField.set(value.startOf('month'))
    }
  }, [ firstDateOfCentralCalendarField, field, format, from, fromField, pickPeriod, to, toField ])

  const getHoverBoundsInitial = () => {
    if (field.state.value && pickPeriod) {
      return { hoverTo: to }
    } else {
      return {}
    }
  }

  const [ { hoverTo }, setHoverBounds ] = useState<{ hoverTo?: Dayjs }>(getHoverBoundsInitial)

  const rootRef = useRef<HTMLDivElement>()

  useEffect(() => {
    rootRef.current.focus() // autofocus on picker
  }, [])

  const centralCalendarIndex = Math.floor(calendarsCount / 2)

  return (
    <div
      className={cx(className, 'flex radius-16 p-20px bg-amelie z-803', {
        'big-shadow-titanic-1': withShadow,
      })}
      style={{ width: 4 + (276 * calendarsCount) + Math.floor(calendarsCount / 2) * 32, height: 260 }}
      ref={rootRef}
      tabIndex={0}
      onBlur={(event) => {
        if (typeof onBlur === 'function') {
          onBlur(event)
        }
      }}
    >
      {
        Array.from({ length: calendarsCount }).map((_, index) => {
          const firstDate = firstDateOfCentralCalendar.add(index - centralCalendarIndex, 'months')

          const month = firstDate.month()
          const firstDayOfMonth = firstDate.day()
          // Sunday is the first day of week in dayjs
          const startOffset = [ -6, 0, -1, -2, -3, -4, -5 ][firstDayOfMonth]

          const emptyDays = Array.from({ length: Math.abs(startOffset) })

          const days = calendarsCount > 1
            ? Array.from({ length: firstDate.daysInMonth() }, (_, index) => firstDate.add(index, 'day'))
            : array
              .range(startOffset, daysInMonthView + startOffset - 1)
              .map((offset) => firstDate.add(offset, 'day'))

          return (
            <div className={cx({ 'ml-32px': index })}>
              <div className="flex items-center">
                {
                  index === 0 && (
                    <>
                      <Href
                        className="pointer"
                        onClick={() => firstDateOfCentralCalendarField.set(firstDateOfCentralCalendar.add(-1, 'year'))}
                      >
                        <Icon className={s.rotate} name="player/forward_16" color="rocky" />
                      </Href>
                      <Href
                        className="pointer ml-16px"
                        onClick={() => firstDateOfCentralCalendarField.set(firstDateOfCentralCalendar.add(-1, 'month'))}
                      >
                        <Icon name="arrow/arrow-left_16" color="rocky" />
                      </Href>
                    </>
                  )
                }
                <Text
                  className="flex-1 text-center capitalize select-none"
                  message={firstDate.format('MMMM YYYY')}
                  size="c16"
                />
                {
                  index === calendarsCount - 1 && (
                    <>
                      <Href
                        className="pointer"
                        onClick={() => firstDateOfCentralCalendarField.set(firstDateOfCentralCalendar.add(1, 'month'))}
                      >
                        <Icon name="arrow/arrow-right_16" color="rocky" />
                      </Href>
                      <Href
                        className="pointer ml-16px"
                        onClick={() => firstDateOfCentralCalendarField.set(firstDateOfCentralCalendar.add(1, 'year'))}
                      >
                        <Icon name="player/forward_16" color="rocky" />
                      </Href>
                    </>
                  )
                }
              </div>
              <div className={cx(s.days, 'pt-16px flex flex-wrap')}>
                {
                  weekDays.map((weekDay, index) => (
                    <Text
                      key={index}
                      className={cx(s.weekDay, 'flex items-center justify-center opacity-48 mx-8px select-none')}
                      size="s13-r"
                      message={weekDay}
                      color={index < 5 ? 'titanic' : 'fargo'}
                    />
                  ))
                }
                {
                  calendarsCount > 1 && emptyDays.map(() => (
                    <div className={cx(s.day, 'mt-12px mx-8px')}></div>
                  ))
                }
                {
                  days.map((day, index) => {
                    const date = day.date()
                    const isThisMonth = day.month() === month
                    const isSelected = stringFieldValue === day.format(format) || day.isSame(to) || day.isSame(from)
                    const isAfterToday = day.isAfter(dayjs())
                    const isPicked = pickedDates?.some((date) => dayjs(date.day, format).isSame(day))

                    return (
                      <Href
                        key={index}
                        className={cx(s.day, 'flex items-center justify-center mt-12px mx-8px relative', {
                          'pointer': !isPickOnlyBeforeToday || (isPickOnlyBeforeToday && !isAfterToday),
                          [ s.hoverable ]: !isPickOnlyBeforeToday || (isPickOnlyBeforeToday && !isAfterToday),
                          [ s.selected ]: isSelected,
                          [ s.picked ]: isPicked,
                          [ s.hovered ]: from !== '' && from.isBefore(day) && hoverTo?.isAfter(day),
                        })}
                        onClick={() => {
                          if (isPickOnlyBeforeToday && isAfterToday) {
                            return
                          }
                          handleDayClick(day)
                        }}
                        onMouseEnter={() => {
                          if (from && !to) {
                            if (isPickOnlyBeforeToday && isAfterToday) {
                              return
                            }
                            if (from.isAfter(day)) {
                              return
                            }
                            setHoverBounds({ hoverTo: day })
                          }
                        }}
                      >
                        <Text
                          className={cx({
                            'opacity-16': !isThisMonth,
                          })}
                          size="s13-r"
                          message={date.toString()}
                          color="interstellar"
                        />
                      </Href>
                    )
                  })
                }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}


export default DatePicker
