import cx from 'classnames'
import { array } from 'helpers'
import dayjs, { Dayjs } from 'date'
import { Field, useField, useFieldState } from 'formular'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Href } from 'components/navigation'
import { Icon, Text } from 'components/dataDisplay'

import s from './MultipleDatePicker.scss'


export type MultipleDatePickerProps = {
  className?: string
  field: Field<string[]> // [ '07.10.2023', '10.10.2023', '11.10.2023' ]
  format?: 'DD.MM.YYYY' | 'DD.MM' | 'YYYY.MM.DD' | 'YYYY-MM-DD'
  calendarsCount?: number
  withShadow?: boolean
  isPickOnlyBeforeToday?: boolean
  onChange?: (value: string[]) => void
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void
  onPicked?: (value: string[]) => void
}

const weekDays = [ 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс' ]
const daysInMonthView = 6 * 7

const MultipleDatePicker: React.FC<MultipleDatePickerProps> = (props) => {
  const {
    className,
    field,
    format = 'DD.MM.YYYY',
    calendarsCount = 1,
    withShadow,
    isPickOnlyBeforeToday,
    onBlur,
  } = props

  const centralCalendarIndex = Math.floor(calendarsCount / 2)

  const { value: dates } = useFieldState(field)

  const [ centralCalendarFirstDate, setCentralCalendarFirstDate ] = useState<Dayjs>(dayjs().startOf('month'))

  const [ lastAddedDayIndex, setLastAddedDayIndex ] = useState<number>()

  const handleDayClick = useCallback((day: Dayjs, shiftKey: boolean) => {
    const existedIndex = dates.findIndex((date) => dayjs(date, format).isSame(day))
    if (existedIndex === -1) {
      const newDates = shiftKey && lastAddedDayIndex
        ? Array
          .from({
            length: day.diff(dayjs(dates[ lastAddedDayIndex ], format).add(1, 'days'), 'days') + 1,
          })
          .map((_, index) => dayjs(dates[ lastAddedDayIndex ], format).add(1, 'days').add(index, 'days').format(format))
          .filter((day) => !dates.includes(day))
        : [ day.format(format) ]

      field.set([ ...dates, ...newDates ])
      setLastAddedDayIndex(dates.length + newDates.length - 1)
    } else {
      field.set([ ...dates.slice(0, existedIndex), ...dates.slice(existedIndex + 1) ])
    }
  }, [ dates, field, format, lastAddedDayIndex ])

  const rootRef = useRef<HTMLDivElement>()

  useEffect(() => {
    rootRef.current.focus() // autofocus on picker
  }, [])

  return (
    <div
      className={cx(className, 'flex radius-16 p-20px bg-amelie z-803', {
        'big-shadow-titanic-1': withShadow,
      })}
      style={{ width: 4 + (276 * calendarsCount) + Math.floor(calendarsCount / 2) * 32, height: 260 }}
      ref={rootRef}
      tabIndex={0}
      onBlur={(event) => {
        if (typeof onBlur === 'function') {
          onBlur(event)
        }
      }}
    >
      {
        Array.from({ length: calendarsCount }).map((_, index) => {
          const firstDate = centralCalendarFirstDate.add(index - centralCalendarIndex, 'months')

          const month = firstDate.month()
          const firstDayOfMonth = firstDate.day()
          // Sunday is the first day of week in dayjs
          const startOffset = [ -6, 0, -1, -2, -3, -4, -5 ][firstDayOfMonth]

          const emptyDays = Array.from({ length: Math.abs(startOffset) })

          const days = calendarsCount > 1
            ? Array.from({ length: firstDate.daysInMonth() }, (_, index) => firstDate.add(index, 'day'))
            : array
              .range(startOffset, daysInMonthView + startOffset - 1)
              .map((offset) => firstDate.add(offset, 'day'))

          return (
            <div className={cx({ 'ml-32px': index })}>
              <div className="flex items-center">
                {
                  index === 0 && (
                    <>
                      <Href
                        className="pointer"
                        onClick={() => setCentralCalendarFirstDate(centralCalendarFirstDate.add(-1, 'year'))}
                      >
                        <Icon className={s.rotate} name="player/forward_16" color="rocky" />
                      </Href>
                      <Href
                        className="pointer ml-16px"
                        onClick={() => setCentralCalendarFirstDate(centralCalendarFirstDate.add(-1, 'month'))}
                      >
                        <Icon name="arrow/arrow-left_16" color="rocky" />
                      </Href>
                    </>
                  )
                }
                <Text
                  className="flex-1 text-center capitalize select-none"
                  message={firstDate.format('MMMM YYYY')}
                  size="c16"
                />
                {
                  index === calendarsCount - 1 && (
                    <>
                      <Href
                        className="pointer"
                        onClick={() => setCentralCalendarFirstDate(centralCalendarFirstDate.add(1, 'month'))}
                      >
                        <Icon name="arrow/arrow-right_16" color="rocky" />
                      </Href>
                      <Href
                        className="pointer ml-16px"
                        onClick={() => setCentralCalendarFirstDate(centralCalendarFirstDate.add(1, 'year'))}
                      >
                        <Icon name="player/forward_16" color="rocky" />
                      </Href>
                    </>
                  )
                }
              </div>
              <div className={cx(s.days, 'pt-16px flex flex-wrap')}>
                {
                  weekDays.map((weekDay, index) => (
                    <Text
                      key={index}
                      className={cx(s.weekDay, 'flex items-center justify-center opacity-48 mx-8px select-none')}
                      size="s13-r"
                      message={weekDay}
                      color={index < 5 ? 'titanic' : 'fargo'}
                    />
                  ))
                }
                {
                  calendarsCount > 1 && emptyDays.map(() => (
                    <div className={cx(s.day, 'mt-12px mx-8px')}></div>
                  ))
                }
                {
                  days.map((day, index) => {
                    const date = day.date()
                    const isThisMonth = day.month() === month
                    const isSelected = dates.some((date) => dayjs(date, format).isSame(day))
                    const isAfterToday = day.isAfter(dayjs())

                    return (
                      <Href
                        key={index}
                        className={cx(s.day, 'flex items-center justify-center mt-12px mx-8px relative select-none', {
                          'pointer': !isPickOnlyBeforeToday || (isPickOnlyBeforeToday && !isAfterToday),
                          [ s.hoverable ]: !isPickOnlyBeforeToday || (isPickOnlyBeforeToday && !isAfterToday),
                          [ s.selected ]: isSelected,
                        })}
                        onClick={(event) => {
                          if (isPickOnlyBeforeToday && isAfterToday) {
                            return
                          }

                          handleDayClick(day, event.shiftKey)
                        }}
                      >
                        <Text
                          className={cx({
                            'opacity-16': !isThisMonth,
                          })}
                          size="s13-r"
                          message={date.toString()}
                          color="interstellar"
                        />
                      </Href>
                    )
                  })
                }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}


export default MultipleDatePicker
