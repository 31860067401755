import cx from 'classnames'
import { Field, useFieldState } from 'formular'
import React, { useCallback, useState } from 'react'

import { Text, CheckboxIcon, IconName, Icon, IconColor, TextColor } from 'components/dataDisplay'

import s from './Checkbox.scss'


export type CheckboxProps = {
  children?: any
  className?: string
  textClassName?: string
  labelClassName?: string
  field: Field<boolean | Array<string> | Array<number>>
  label?: string
  labelColor?: TextColor
  value?: any
  disabled?: boolean
  fullWidth?: boolean
  isHover?: boolean
  ariaLabel?: string
  dataTestId?: string
  text?: string
  icon?: IconName
  iconColor?: IconColor
  onClick?: (value?: string) => void
}

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const {
    className, labelClassName, field, label, value, textClassName,
    disabled, fullWidth,
    ariaLabel, dataTestId, text, onClick, icon, iconColor, labelColor,
  } = props

  const state = useFieldState(field)
  const [ isHover, setIsHover ] = useState(false)

  const isCheckboxGroup = Array.isArray(state.value)
  const isChecked = isCheckboxGroup ? state.value.includes(value) : state.value

  const rootClassName = cx(s.root, className, 'inline-flex items-center', {
    [s.disabled]: disabled,
    'w-full': fullWidth,
  })

  const handleClick = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()

    // checkbox group
    if (Array.isArray(state.value)) {
      if (state.value.includes(value)) {
        field.set(state.value.filter((v) => v !== value))
      }
      else {
        field.set([ ...state.value, value ])
      }
    }
    // single checkbox
    else {
      field.set(!state.value)
    }

    if (typeof onClick === 'function') {
      onClick(value)
    }

  }, [ field, state.value, value, onClick ])

  return (
    <div
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <button
        className={rootClassName}
        role="checkbox"
        disabled={disabled}
        aria-label={ariaLabel}
        aria-checked={value}
        data-testid={dataTestId}
        onClick={handleClick}
      >
        <CheckboxIcon
          className="flex-none"
          active={isChecked}
          disabled={disabled}
          isHover={isHover}
        />
        {
          icon && (
            <Icon
              className="ml-6px"
              name={icon}
              color={iconColor}
            />
          )
        }
        {
          Boolean(label) && (
            <Text
              className={cx(
                'ml-6px',
                disabled ? 'opacity-16' : isHover ? 'opacity-100' : 'opacity-72',
                labelClassName
              )}
              message={label}
              size="c16"
              color={labelColor || 'titanic'}
              html
            />
          )
        }
      </button>
      {
        Boolean(text) && (
          <Text
            className={cx(disabled ? 'opacity-32' : isHover ? 'opacity-56' : 'opacity-48', 'pt-8px select-none pointer', textClassName)}
            message={text}
            size="s13-r"
            color="titanic"
            onClick={handleClick}
            tag="button"
          />
        )
      }
    </div>
  )
}


export default Checkbox
